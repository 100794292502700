import { Button } from '@consigli/facade';
import { useProjectId, useServiceCategory } from '@consigli/hooks';
import { ServiceCategory } from '@consigli/types';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Route } from '@/routes';

export const WaitingForAnalysis: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectId = useProjectId();
  const serviceCategory = useServiceCategory();

  const message = useMemo(() => {
    if (serviceCategory === ServiceCategory.LAYOUT_OPTIMIZATION) {
      return t('dashboard.waiting-for-analysis-optimization');
    } else {
      return t('dashboard.waiting-for-analysis-insight');
    }
  }, [serviceCategory, t]);

  const handleOnClick = useCallback(() => {
    navigate(`/${Route.PROJECTS}/${projectId}/${Route.SERVICES}`);
  }, [navigate, projectId]);

  return (
    <div className="flex flex-col justify-center flex-1 bg-day-light-4 text-day-neutral-dark p-4">
      <div className="flex flex-col justify-center items-center w-full sm:w-[30rem] mx-auto">
        <div className="text-[32px] font-semibold text-center">
          {t('dashboard.documents-processing')}
        </div>
        <div className="text-base rounded-md py-6 font-semibold text-center">{message}</div>
        <Button primary rounded onClick={handleOnClick}>
          {t('dashboard.back-to-project')}
        </Button>
      </div>
    </div>
  );
};
